import React from "react";
import { Datas } from "../../../common/types";

type Props = {
  datas: Datas;
};

const MenuEventCreater: React.VFC<Props> = ({ datas }: Props) => {
  return <></>;
};
export default MenuEventCreater;
