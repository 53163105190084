import React from "react";
import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  Divider,
  TextField,
  Button,
} from "@mui/material";

import { Datas, EventDatas } from "../../../common/types";
import {
  deleteEventRoutes,
  updateEventRoutes,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { addCommandPicture } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import ImageSetting from "../../../common/lib/ImageSetting";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventRoute: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  const navigate = useNavigate();

  const [imageurls, setImageurls] = useState<string[]>(
    eventDatas.EventRoute.map(() => "")
  );

  async function setimagefunc(
    downloadURL: string,
    eventDataId: string,
    routeId: string,
    commandId: string
  ): Promise<boolean> {
    updateEventRoutes(eventDataId, routeId, { imageURL: downloadURL });
    addCommandPicture(eventDatas.eventData.ekiID, commandId, {
      imageURL: downloadURL,
    });
    return true;
  }

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          {eventDatas.eventData.eki}：{eventDatas.eventData.eventDateString}
          <br />
          <br />
          散歩ルートの計画と実績を整理しましょう
          <br />
          <br />
          <br />
          {eventDatas.EventRoute.map((item: any, index: any) => (
            <>
              {item.num}:{item.text}::
              {item.routeid}
              <Button
                onClick={() => {
                  console.log(item);
                  deleteEventRoutes(eventDatas.eventData.id, item.id);
                }}
              >
                削除
              </Button>
              <br />
              <ImageSetting
                imageurl={imageurls[index]}
                setimageurl={(newUrl: string) => {
                  const updatedImageUrls = [...imageurls];
                  updatedImageUrls[index] = newUrl;
                  setImageurls(updatedImageUrls);
                }}
                setimagefunc={(downloadURL: string) =>
                  setimagefunc(
                    downloadURL,
                    eventDatas.eventData.id,
                    item.id,
                    item.commandId
                  )
                }
                strageurl={
                  "YamanoteRoute/" +
                  eventDatas.eventData.ekiID +
                  "/Command/" +
                  item.id +
                  "/userupload/"
                }
              />
              <br />
            </>
          ))}
          <Button
            onClick={() => {
              navigate(
                "/Shizigatya/" +
                  eventDatas.eventData.id +
                  "/" +
                  eventDatas.eventData.ekiID
              );
            }}
          >
            ルート追加（ガチャる）
          </Button>
        </Paper>
        <br />
      </Container>
    </>
  );
};
export default MenuEventRoute;
