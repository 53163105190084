import React, { useReducer, useEffect, useState, useMemo } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";

import Router from "./Router";

import { getDatas } from "./common/firebaseHelper/FirebaseTransactionHelper";
import { lighttheme } from "./common/colors";

import { otherApp } from "./common/firebaseHelper/firebase";
import { getUserPublic } from "./common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import { useWindowSize } from "react-use";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { adminUid } from "./common/AdminConfig";

function App() {
  const [isParamReady, setIsParamReady] = useState(false);
  const { width, height } = useWindowSize();
  const [bgColor, setBgColor] = useState({});
  const [styletext, setStyles] = useState({
    widthUnder: 100,
    windowWidth: 100,
    displayWidth: 100,
    windowHeight: 100,
  });

  const [routeList, setRouteList] = useState([]);

  const [userInfo, setUserInfo] = useState({
    isLogin: false,
    userUid: "",
    avatar: {
      img: "../nanashi.png",
      name: "名無し",
    },
    adminFlag: false,
  });

  // const [isLogin, setIsLogin] = useState(false);
  // const [avatar, setAvatar] = useState({
  //   img: "../nanashi.png",
  //   name: "名無し",
  // });

  const datas = useMemo(
    () => ({
      get bgColor() {
        return bgColor;
      },
      setBgColor,
      get styletext() {
        return styletext;
      },
      setStyles,
      get routeList() {
        return routeList;
      },
      setRouteList,
      get userInfo() {
        return userInfo;
      },
      setUserInfo,
    }),
    [bgColor, styletext, routeList, userInfo]
  );

  const initSetStyle = () => {
    if (width < 300) {
      setStyles({
        widthUnder: 300,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 500) {
      setStyles({
        widthUnder: 500,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 700) {
      setStyles({
        widthUnder: 700,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 900) {
      setStyles({
        widthUnder: 900,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 1200) {
      setStyles({
        widthUnder: 1200,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1300) {
      setStyles({
        widthUnder: 1300,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1400) {
      setStyles({
        widthUnder: 1400,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1600) {
      setStyles({
        widthUnder: 1600,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1700) {
      setStyles({
        widthUnder: 1700,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1800) {
      setStyles({
        widthUnder: 1800,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1900) {
      setStyles({
        widthUnder: 1900,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else {
      setStyles({
        widthUnder: 2000,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    }
  };

  const initRoutelist = async () => {
    const querySnapshot = await getDatas();
    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      lists.push({
        id: doc.id,
        num: doc.data().num,
        text: doc.data().text,
        describe: doc.data().describe,
        isActive: true,
      });
    });
    setRouteList(lists);
  };

  const initUser = async () => {
    const usercheck = onAuthStateChanged(getAuth(otherApp), async (user) => {
      // console.log("通過");
      // console.log(user)
      if (user) {
        const thisAdminFlag = adminUid.includes(user.uid) ? true : false;
        // setIsLogin(true);
        const avatarDoc = await getUserPublic(user.uid);
        if (
          typeof avatarDoc.data() !== "undefined" &&
          avatarDoc.data().imageurl
        ) {
          setUserInfo({
            isLogin: true,
            userUid: user.uid,
            avatar: {
              img: avatarDoc.data().imageurl,
              name: avatarDoc.data().name,
            },
            adminFlag: thisAdminFlag,
          });
        } else {
          setUserInfo({
            isLogin: true,
            userUid: user.uid,
            avatar: {
              img: "../nanashi.png",
              name: "名無し",
            },
            adminFlag: thisAdminFlag,
          });
        }
      } else {
        setUserInfo({
          isLogin: false,
          userUid: "",
          avatar: {
            img: "../nanashi.png",
            name: "名無し",
          },
          adminFlag: false,
        });
      }
    });
  };

  useEffect(() => {
    const f = async () => {
      await setBgColor(lighttheme);
      await initSetStyle();
      await initRoutelist();
      await initUser();
      setIsParamReady(true);
    };
    f();
  }, []);

  return (
    <>
      {isParamReady ? <Router datas={datas} /> : <>{console.log("loding")}</>}
    </>
  );
}

export default App;
