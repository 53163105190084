import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import moment from "moment";

import {
  getEvent,
  updateEvent,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getAuth } from "firebase/auth";

type Props = {
  datas: Datas;

  eventData: any;
  setEventData: any;
  updatePlan: any;
  deletePlan: any;
};

const EditEventView: React.VFC<Props> = ({
  datas,
  eventData,
  setEventData,
  // eventDate,
  // setEventDateString,
  // setEventDate,
  // title,
  // setTitle,
  updatePlan,
  deletePlan,
}: Props) => {
  const navigate = useNavigate();

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };
  return (
    <>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
          padding: "20px",
        }}
      >
        編集
        <TextField
          label="表示名"
          name="Name"
          value={eventData.title}
          variant="standard"
          fullWidth
          margin="dense"
          onChange={(e) => {
            setEventData((prevData: any) => ({
              ...prevData,
              title: e.target.value,
            }));
            // setTitle(e.target.value);
          }}
          sx={{
            input: {
              color: "#000000",
            },
          }}
          style={{
            border: "#000000",
          }}
          InputLabelProps={{
            style: {
              color: "#000000",
            }, // ここに希望の色を指定
          }}
        />
        <br />
        駅名：{eventData.eki}
        <br />
        コメント：{eventData.comment}
        <br />
        {}
        <br />
        開催日 :
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="日付を選択"
            value={dayjs(eventData.eventDate)}
            format="YYYY/MM/DD"
            onChange={(newValue: any) => {
              const eventDateString = parseAsMoment(newValue.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString();

              setEventData((prevData: any) => ({
                ...prevData,
                eventDateString: eventDateString,
                eventDate: newValue ? newValue.toDate() : null,
              }));

              // setEventDateString(eventDateString);
              // setEventDate(newValue ? newValue.toDate() : null);
            }}
            // renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="開始時間を選択"
            value={dayjs(eventData.eventDate)}
            onChange={(newValue: any) => {
              const eventDateString = parseAsMoment(newValue.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString();

              setEventData((prevData: any) => ({
                ...prevData,
                eventDateString: eventDateString,
                eventDate: newValue ? newValue.toDate() : null,
              }));
            }}
          />
        </LocalizationProvider>
        <br />
        <br />
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: datas.bgColor.back06,
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            updatePlan();
          }}
        >
          変更
        </Button>
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#3498db",
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            deletePlan();
          }}
        >
          削除
        </Button>
      </div>
    </>
  );
};

export default EditEventView;
