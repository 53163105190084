import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { marked } from "marked";

import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import ShizigatyaView from "./ShizigatyaView";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

type Props = {
  datas: Datas;
};

// Markedのオプションを設定
marked.setOptions({
  breaks: true, // 改行を有効にする
  gfm: true, // GitHub Flavored Markdownを有効にする（リストなどの構文の互換性向上）
});

const Shizigatya: React.FC<Props> = ({ datas }) => {
  const [changeCount, setChangeCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      console.log("ue_Event");
    };
    f();
  }, [changeCount]);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    <>
      <Navbar datas={datas} />

      <div
        style={
          datas.styletext.windowWidth > 1200
            ? styles1.container
            : styles2.container
        }
      >
        <ShizigatyaView datas={datas} />
      </div>

      <Footer datas={datas} />
    </>
  );
};

export default Shizigatya;
