import { Datas, BookDatas } from "../../../common/types";

import MenuEvent from "./MenuEvent";
import MenuJoin from "./MenuJoin";
import MenuPlan from "./MenuPlan";
import MenuProfile from "./MenuProfile";
import MenuSignin from "./MeunSignin";
import MenuSignup from "./MenuSignup";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const BookView: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  return (
    <>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          // backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
        }}
      >
        <h1
          style={{
            fontFamily: "'Playfair Display', serif",
            fontSize: "35px",
            fontWeight: 700,
            color: "#2c3e50",
            textAlign: "center",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          山手散歩会
        </h1>

        <p
          style={{
            fontSize: "17px",
            lineHeight: 1.6,
            // textAlign: "justify",
            textAlign: "center",
          }}
        >
          <span
            style={{
              backgroundColor: datas.bgColor.back05,
              padding: "0 5px",
              borderRadius: "3px",
            }}
          >
            イベント
          </span>
          を見つけて、参加してみよう！
        </p>

        <br />
        <div>
          {bookDatas.opendMenu === "profile" ? (
            <MenuProfile datas={datas} bookDatas={bookDatas} />
          ) : bookDatas.opendMenu === "event" ? (
            <MenuEvent datas={datas} bookDatas={bookDatas} />
          ) : bookDatas.opendMenu === "join" ? (
            <MenuJoin datas={datas} bookDatas={bookDatas} />
          ) : bookDatas.opendMenu === "plan" ? (
            <MenuPlan datas={datas} bookDatas={bookDatas} />
          ) : bookDatas.opendMenu === "signin" ? (
            <MenuSignin datas={datas} bookDatas={bookDatas} />
          ) : bookDatas.opendMenu === "signup" ? (
            <MenuSignup datas={datas} bookDatas={bookDatas} />
          ) : (
            <div>選んでみよう</div>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};
export default BookView;
