import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, BookDatas } from "../../../common/types";
import { deleteEventParticipants } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { deleteUserPrivateBook } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const MenuJoin: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 参加予定の一覧
      </div>
      <br />
      {datas.userInfo.isLogin ? (
        <>
          <table
            style={{
              width: datas.styletext.displayWidth,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center", // テーブル内のコンテンツを中央揃え
            }}
          >
            <tbody>
              {bookDatas.eventJoinList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {bookDatas.eventJoinList.map((item: any, index: any) => (
                    <tr key={index}>
                      <td style={cellStyle}>{item.title}</td>

                      <td style={cellStyle}>
                        {item.isDelete ? (
                          <Button
                            style={{
                              display: "inline-block",
                              padding: "10px 10px",
                              fontSize: "18px",
                              color: "#fff",
                              backgroundColor: "#d9534f", // 削除ボタンは赤色
                              textDecoration: "none",
                              borderRadius: "5px",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              transition: "background-color 0.3s ease",
                            }}
                            onClick={async () => {
                              try {
                                // 削除処理の関数を await で呼び出し
                                await deleteEventParticipants(
                                  item.id,
                                  datas.userInfo.userUid
                                );
                                await deleteUserPrivateBook(
                                  datas.userInfo.userUid,
                                  item.id
                                );

                                // 削除が完了したらページ遷移
                                navigate("/Event/" + item.id);
                              } catch (error) {
                                console.error(
                                  "削除処理でエラーが発生しました:",
                                  error
                                );
                                alert(
                                  "削除に失敗しました。もう一度お試しください。"
                                );
                              }
                            }}
                          >
                            削除
                          </Button>
                        ) : (
                          <Button
                            style={{
                              display: "inline-block",
                              padding: "10px 10px",
                              fontSize: "18px",
                              color: "#fff",
                              backgroundColor: datas.bgColor.back06,
                              textDecoration: "none",
                              borderRadius: "5px",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              transition: "background-color 0.3s ease",
                            }}
                            onClick={() => {
                              navigate("/Event/" + item.id);
                            }}
                          >
                            詳細
                          </Button>
                        )}

                        {/* isDelete が true の場合に "イベントは中止" と表示 */}
                        {item.isDelete && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              marginLeft: "10px",
                            }}
                          >
                            イベントは中止
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <br />
          <div
            style={{
              textAlign: "center",
              backgroundColor: datas.bgColor.back05,
            }}
          >
            🔳 過去の参加一覧
          </div>
          <table
            style={{
              width: datas.styletext.displayWidth,
            }}
          >
            <tbody>
              {bookDatas.eventJoinPastList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {bookDatas.eventJoinPastList.map((item: any, index: any) => (
                    <tr>
                      <td style={cellStyle}>{item.title}</td>

                      <td style={cellStyle}>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: datas.bgColor.back06,
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/Event/" + item.id);
                          }}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <br />
          {/* <div style={{ textAlign: "center" }}>
            過去参加イベントがありません
          </div> */}
          <br />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              bookDatas.setOpendMenu("signin");
            }}
          >
            ログイン
          </Button>
          しましょう
        </div>
      )}
    </div>
  );
};

export default MenuJoin;
