import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import DeveloperView from "./DeveloperView";
import LodingModal from "../../Modal/LodingModal/LodingModal";

type Props = {
  datas: Datas;
};

const Developer: React.VFC<Props> = ({ datas }: Props) => {
  const [isParamReady, setIsParamReady] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  useEffect(() => {
    console.log("uE_Developer");
    const f = async () => {
      setIsParamReady(datas.userInfo.adminFlag);
      setLodingmodalopen(!datas.userInfo.adminFlag);

      console.log(datas.userInfo.adminFlag);
    };
    f();
  }, []);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };
  return (
    <div>
      {isParamReady ? (
        <>
          <Navbar datas={datas} />

          <div
            style={
              datas.styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <DeveloperView datas={datas} />
          </div>

          <Footer datas={datas} />
        </>
      ) : (
        <>loding</>
      )}
      <LodingModal open={lodingmodalopen} />
    </div>
  );
};
export default Developer;
