import React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button, TextField, Checkbox, Link } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

import ChoiceEki from "./ChoiceEki";

import { getGatheringPlaces } from "../../../common/firebaseHelper/FirebaseTransactionHelperGatheringPlace";

type Props = {
  datas: Datas;

  choiceEki: any;
  setChoiceEki: any;

  choiceDate: any;
  setChoiceDate: any;
  choiceTime: any;
  setChoiceTime: any;
  title: any;
  setTitle: any;
  handleChangeTerm: any;
  termcheck: any;

  setOpendMenu: any;

  choiceGatheringPlace: any;
  setChoiceGatheringPlace: any;

  gatheringPlaceList: any;
  setGatheringPlaceList: any;
};

const CreateEventWrite2: React.FC<Props> = ({
  datas,
  choiceEki,
  setChoiceEki,

  choiceDate,
  setChoiceDate,
  choiceTime,
  setChoiceTime,
  title,
  setTitle,
  handleChangeTerm,
  termcheck,

  setOpendMenu,

  choiceGatheringPlace,
  setChoiceGatheringPlace,

  gatheringPlaceList,
  setGatheringPlaceList,
}) => {
  useEffect(() => {
    const f = async () => {
      console.log("uE_CreateEventWrite2");
      // console.log(choiceEki.id);

      //掲示板を確認
      const querySnapshot = await getGatheringPlaces(choiceEki.id);
      let lists: any = [];
      querySnapshot.forEach((doc: any) => {
        // console.log(doc.id);
        lists.push({
          id: doc.id,
          num: doc.data().num,
          text: doc.data().text,
          imageURL: doc.data().imageURL,
        });
      });
      setGatheringPlaceList(lists);
      if (lists.length > 0) {
        setChoiceGatheringPlace(lists[0]);
      }
      // console.log(lists);
    };
    f();
  }, []);
  return (
    <div
      style={{
        fontFamily: "'Roboto', sans-serif",
        // backgroundColor: "#f4f4f4",
        color: "#333",
        margin: 0,
        padding: "20px",
      }}
    >
      <br />
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 集合場所設定
      </div>
      {gatheringPlaceList.map((item: any, index: any) => (
        <>
          <Button
            onClick={() => {
              setChoiceGatheringPlace(item);
            }}
            style={{
              border:
                choiceGatheringPlace === item ? "3px solid green" : "none",
            }}
          >
            {item.text} :
            <img
              src={item.imageURL}
              style={{
                maxHeight: 120,
                maxWidth: 120,
              }}
            />
          </Button>

          <br />
        </>
      ))}
      <br />
      <br />
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 説明文
      </div>
      <br />
      <TextField
        name="Name"
        value={title}
        variant="standard"
        fullWidth
        margin="dense"
        multiline
        rows={50} // 表示する行数を指定
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        sx={{
          input: {
            color: "#000000",
          },
        }}
        style={{
          border: "#000000",
        }}
        InputLabelProps={{
          style: {
            color: "#000000",
          }, // ここに希望の色を指定
        }}
      />
      <br /> <br />
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 利用規約
      </div>
      <br />
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <Checkbox
          style={{
            color: datas.bgColor.back02,
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={handleChangeTerm}
          checked={termcheck}
        />
        <Link color="inherit" href="http://lonpa.net/Terms" target="_blank">
          利用規約
        </Link>
        に同意します
      </div>
      <br />
      <br />
      <br />
      <Button
        style={{
          display: "inline-block",
          padding: "10px 10px",
          fontSize: "18px",
          color: "#fff",
          backgroundColor: termcheck
            ? datas.bgColor.back06
            : datas.bgColor.back05,
          textDecoration: "none",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
          width: "100%",
        }}
        disabled={!termcheck}
        onClick={() => {
          setOpendMenu("confilm");
        }}
      >
        作成
      </Button>
      <Button
        style={{
          display: "inline-block",
          padding: "10px 10px",
          fontSize: "15px",
          color: "#000",
          // backgroundColor: datas.bgColor.back03,
          textDecoration: "none",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
          width: "100%",
        }}
        onClick={() => {
          setOpendMenu("one");
        }}
      >
        戻る
      </Button>
      <br />
      <br />
    </div>
  );
};
export default CreateEventWrite2;
