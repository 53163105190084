import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, BookDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const MenuPlan: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 イベント作成はこちら
      </div>
      <br />

      <Button
        style={{
          width: "calc(100% - 40px)",
          display: "inline-block",
          padding: "10px 10px",
          fontSize: "18px",
          color: "#fff",
          backgroundColor: datas.bgColor.back06,
          textDecoration: "none",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
          margin: "0 20px",
        }}
        onClick={() => {
          navigate("/createEvent/");
        }}
      >
        作成
      </Button>
      <br />
      <br />
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 主催予定の一覧
      </div>

      {datas.userInfo.isLogin ? (
        <>
          <table
            style={{
              width: datas.styletext.displayWidth,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center", // テーブル内のコンテンツを中央揃え
            }}
          >
            <tbody>
              {bookDatas.eventPlanList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>あなたが主催のイベントはありません</td>
                </tr>
              ) : (
                <>
                  {bookDatas.eventPlanList.map((item: any, index: any) => (
                    <tr>
                      <td style={cellStyle}>{item.title}</td>

                      <td style={cellStyle}>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: datas.bgColor.back06,
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/EditEvent/" + item.id);
                          }}
                        >
                          編集
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              bookDatas.setOpendMenu("signin");
            }}
          >
            ログイン
          </Button>
          しましょう
        </>
      )}
    </div>
  );
};

export default MenuPlan;
