import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Paper,
  Typography,
  List,
  Divider,
  TextField,
  Button,
} from "@mui/material";

import { Datas, EventDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventAfterBook: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {/* イベントID: {eventid} */}
            {eventDatas.eventData.title}
          </Typography>
          <br />
          日程：{eventDatas.eventData.eventDateString}
          <br />
          駅名：{eventDatas.eventData.eki}
          <br />
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              eventDatas.setOpendMenu("eventRoute");
            }}
          >
            ルートメーカー
          </Button>
          <br />
          集合場所:{eventDatas.eventData.gatheringPlace.text}
          <br />
          <img
            src={eventDatas.eventData.gatheringPlace.imageURL}
            style={{
              maxHeight: 240,
              maxWidth: 240,
            }}
          />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: eventDatas.eventData.comment,
            }}
          />
          <br />
          <div style={{ color: datas.bgColor.back01 }}>
            {" "}
            {eventDatas.eventData.id}
          </div>
          <br />
          {!datas.userInfo.isLogin ? (
            <>ここは表示されないはず</>
          ) : eventDatas.isParticipation ? (
            <>
              <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: datas.bgColor.back06,
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                  width: "100%",
                }}
                onClick={() => {
                  eventDatas.cancelUser(eventDatas.eventData.id);
                }}
              >
                参加を取り消す
              </Button>
            </>
          ) : (
            <>ここは表示されないはず</>
          )}
          <br />
          <br />
          <br />
          <u>🔳 イベント参加者</u>
          <br />
          {eventDatas.eventParticipantsList.map((item: any, index: any) => (
            <>{item.name}:</>
          ))}
          <br />
          <br />
          <br />
          <u>🔳 参加者チャット</u>
          <List>
            {eventDatas.BBS.map((bbsdata: any) => (
              <Fragment key={"data.id"}>
                {bbsdata.name} :{bbsdata.content}
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
          <TextField
            label="投稿内容"
            fullWidth
            margin="normal"
            value={eventDatas.content}
            onChange={(e) => eventDatas.setContent(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: datas.bgColor.back06,
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
              width: "100%",
            }}
            onClick={() => {
              eventDatas.PostBBS();
            }}
          >
            投稿する
          </Button>
        </Paper>
        <br />
      </Container>
    </>
  );
};
export default MenuEventAfterBook;
