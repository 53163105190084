import { useEffect, useState } from "react";
import { Datas } from "../../common/types";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getUserSecret } from "../../common/firebaseHelper/FirebaseTransactionHelperUserSecret";
import { getUserPublic } from "../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import { firebaseApp, otherApp } from "../../common/firebaseHelper/firebase";

import { useNavigate } from "react-router-dom";
import { Container, Grid, Button } from "@mui/material";

import createCheckoutSession from "../../common/stripeHelper/createCheckoutSession";
import LodingModal from "../Modal/LodingModal/LodingModal";
import CheckIcon from "@mui/icons-material/Check";
import RoleButtonMake from "./RoleButtonMake";
import { TramRounded } from "@mui/icons-material";

import Navbar from "../Navbar/Navbar";

type Props = {
  datas: Datas;
};

const Point: React.VFC<Props> = ({ datas }: Props) => {
  //   const classes = useStyles();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userIsNotLogin, setUserIsNotLogin] = useState(false);
  const [userIsOnlyLogin, setUserIsOnlyLogin] = useState(false);
  const [userIsStandard, setUserIsStandard] = useState(false);
  const [userIsPro, setUserIsPro] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    fontSize: "14px",
  };

  useEffect(() => {
    console.log("uE_Point");
    setLodingmodalopen(true);

    onAuthStateChanged(getAuth(otherApp), async (user) => {
      if (user) {
        setUserId(user.uid);
        //setUserEmail(user.email);
        setUserIsNotLogin(false);
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();

        //console.log(decodedToken);

        if (decodedToken.claims.stripeRole == "standard") {
          setUserIsStandard(true);
          setUserIsPro(false);
          setUserIsOnlyLogin(false);
        } else if (decodedToken.claims.stripeRole == "pro") {
          setUserIsStandard(false);
          setUserIsPro(true);
          setUserIsOnlyLogin(false);
        } else {
          setUserIsStandard(false);
          setUserIsPro(false);
          setUserIsOnlyLogin(true);
        }
      } else {
        setUserIsNotLogin(true);
      }
    });

    setLodingmodalopen(false);
  }, []);

  return (
    <div
      style={
        {
          // backgroundColor: bgColor.back01,
          // color: bgColor.text01.fontColor,
        }
      }
    >
      <Navbar datas={datas} />
      <div style={{ height: "6vh" }}></div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 id="transition-modal-title">料金プラン</h2>
          </Grid>

          {userIsNotLogin ? (
            <div style={{ width: "100%" }}>
              <br />
              アカウントをすでにお持ちですか？
              <br />
              <Button
                disabled={false}
                size="large"
                variant="contained"
                style={{ fontSize: 15 }}
                onClick={() => {
                  navigate("/Book/signin");
                }}
              >
                ログイン
              </Button>
            </div>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <br />
            <table>
              <tbody>
                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td style={cellStyle}>プラン</td>
                  <td style={cellStyle}>Free</td>
                  <td style={cellStyle}>Standard</td>
                  <td style={cellStyle}>Pro</td>
                </tr>
                <tr>
                  <td style={cellStyle}>月額</td>
                  <td style={cellStyle}>無料</td>
                  <td style={cellStyle}>￥100</td>
                  <td style={cellStyle}>￥5000</td>
                </tr>
                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td style={cellStyle}>point</td>
                  <td style={cellStyle}>0</td>
                  <td style={cellStyle}>100pt</td>
                  <td style={cellStyle}>5000pt</td>
                </tr>
                <tr>
                  <td style={cellStyle}>意見</td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>論拠</td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>原稿</td>
                  <td style={cellStyle}></td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                </tr>

                <tr>
                  <td style={cellStyle}>動画</td>
                  <td style={cellStyle}></td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>自動</td>
                  <td style={cellStyle}></td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                  <td style={cellStyle}>
                    <CheckIcon />
                  </td>
                </tr>

                <tr style={{ borderBottom: "solid 3px #EEE" }}>
                  <td style={cellStyle}></td>
                  <td style={cellStyle}>
                    {/* 左側 */}
                    <RoleButtonMake
                      roleDatas={{
                        notlogin: {
                          flag: userIsNotLogin,
                          text: "登録",
                          color: "error",
                          disabled: false,
                          excute: () => {
                            navigate("/Book/signup");
                          },
                        },
                        onlylogin: {
                          flag: userIsOnlyLogin,
                          text: "確認",
                          color: "primary",
                          disabled: false,
                          excute: () => {
                            navigate("/Book/profile");
                          },
                        },
                        standard: {
                          flag: userIsStandard,
                          text: "不要",
                          color: "primary",
                          disabled: TramRounded,
                          excute: () => {},
                        },
                        pro: {
                          flag: userIsPro,
                          text: "不要",
                          color: "primary",
                          disabled: true,
                          excute: () => {},
                        },
                      }}
                    />
                  </td>

                  <td style={cellStyle}>
                    {/* 真ん中 */}
                    <RoleButtonMake
                      roleDatas={{
                        notlogin: {
                          flag: userIsNotLogin,
                          text: "登録",
                          color: "error",
                          disabled: false,
                          excute: () => {
                            navigate("/Book/signup");
                          },
                        },
                        onlylogin: {
                          flag: userIsOnlyLogin,
                          text: "登録",
                          color: "error",
                          disabled: false,
                          excute: () => {
                            setLodingmodalopen(true);
                            createCheckoutSession(userId, "standard");
                          },
                        },
                        standard: {
                          flag: userIsStandard,
                          text: "確認",
                          color: "primary",
                          disabled: false,
                          excute: () => {
                            navigate("/Payment");
                          },
                        },
                        pro: {
                          flag: userIsPro,
                          text: "不可",
                          color: "primary",
                          disabled: true,
                          excute: () => {},
                        },
                      }}
                    />
                  </td>

                  <td style={cellStyle}>
                    {/* 右：プロ枠 */}
                    <RoleButtonMake
                      roleDatas={{
                        notlogin: {
                          flag: userIsNotLogin,
                          text: "登録",
                          color: "error",
                          disabled: false,
                          excute: () => {
                            navigate("/Book/signup");
                          },
                        },
                        onlylogin: {
                          flag: userIsOnlyLogin,
                          text: "登録",
                          color: "error",
                          disabled: false,
                          excute: () => {
                            setLodingmodalopen(true);
                            createCheckoutSession(userId, "pro");
                          },
                        },
                        standard: {
                          flag: userIsStandard,
                          text: "不可",
                          color: "primary",
                          disabled: true,
                          excute: () => {},
                        },
                        pro: {
                          flag: userIsPro,
                          text: "確認",
                          color: "primary",
                          disabled: false,
                          excute: () => {
                            navigate("/Payment");
                          },
                        },
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
          </Grid>
        </Grid>
      </Container>
      <div style={{ height: "2vh" }}></div>

      {lodingmodalopen ? <LodingModal open={lodingmodalopen} /> : <></>}
    </div>
  );
};
export default Point;
