import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, BookDatas } from "../../../common/types";

import Userpage from "../Userpage/Userpage";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const MenuProfile: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <>
        {datas.userInfo.isLogin ? (
          <Userpage
            bgColor={datas.bgColor}
            styletext={datas.styletext}
            incrementChangeCount={bookDatas.incrementChangeCount}
          />
        ) : (
          <>
            <br />
            profileのページです
            <br />
            まずは
            <Button
              style={{
                display: "inline-block",
                padding: "10px 10px",
                fontSize: "18px",
                color: "#fff",
                backgroundColor: "#CC9900",
                textDecoration: "none",
                borderRadius: "5px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
              }}
              onClick={() => {
                bookDatas.setOpendMenu("signin");
              }}
            >
              ログイン
            </Button>
            しましょう
          </>
        )}
      </>
    </div>
  );
};

export default MenuProfile;
