import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
  Fragment,
  useMemo,
} from "react";

import { marked } from "marked";

import { get3commands } from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { useQRCode } from "next-qrcode";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  getEvent,
  getEventBBSs,
  addEventBBS,
  getEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import {
  setUserPrivateBook,
  updateUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import {
  getEvents,
  setEventParticipants,
  deleteEventParticipants,
  getEventRoutes,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getListOfUsersPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import moment from "moment";

import EventView from "./EventView";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

type Props = {
  datas: Datas;
};

// Markedのオプションを設定
marked.setOptions({
  breaks: true, // 改行を有効にする
  gfm: true, // GitHub Flavored Markdownを有効にする（リストなどの構文の互換性向上）
});

const Event: React.FC<Props> = ({ datas }) => {
  let { eventid } = useParams();
  const [content, setContent] = useState<string>("");

  const [htmlContent, setHtmlContent] = useState<string>("");

  const [eventData, setEventData] = useState({
    id: "",
    title: "",
    comment: "",
    eki: "",
    ekiID: "",
    eventDate: new Date(0),
    eventDateString: "",
    gatheringPlace: { id: "", imageURL: "", num: "", text: "" },
  });

  const [eventTitle, setEventTitle] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date>(new Date(0));
  const [eventDateString, setEventDateString] = useState("");

  const [BBS, setBBS] = useState([]);
  const [EventRoute, setEventRoute] = useState([]);

  const [userDatas, setUserDatas] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [eventParticipantsList, setEventParticipantsList] = useState([]);
  const [opendMenu, setOpendMenu] = useState("beforeBook");
  const [isParticipation, setIsParticipation] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("test");
  };

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const cancelUser = async (event_id: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      await deleteEventParticipants(event_id, user.uid);
      await deleteUserPrivateBook(user.uid, event_id);
      incrementChangeCount();
      navigate("/Book");
    } else {
      // console.log("ログインしていない");
    }
  };

  //参加時に呼ばれる
  const bookUser = async (
    event_id: string,
    event_title: string,
    event_eventDate: any
  ) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      let doc = await getUserPublic(user.uid);
      if (typeof doc.data() !== "undefined") {
        if (typeof doc.data().name !== "undefined") {
          await setEventParticipants(event_id, user.uid, {
            uid: user.uid,
            name: doc.data().name,
          });
          await setUserPrivateBook(user.uid, event_id, {
            id: event_id,
            title: event_title,
            eventDate: event_eventDate,
          });
          incrementChangeCount();
        } else {
          console.log("name undfined");
          alert(
            "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
          );
        }
      } else {
        console.log("data undfined");
        alert(
          "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
        );
      }
    } else {
      // console.log("ログインしていない");
      navigate("/Book/signin");
      // setOpendMenu("signin");
    }
  };

  useEffect(() => {
    const f = async () => {
      console.log("ue_Event");

      //データを取得
      let doc = await getEvent(eventid);

      //イベントタイトルを設定

      //イベントの日程を設定
      if (doc && doc.data() && doc.data().title) {
        //イベントの日程を設定
        // console.log(doc.data());
        if (doc.data().eventDate) {
          const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
            .format("YYYY/MM/DD HH:mm")
            .toString();

          //コメントをマークダウンに
          const markedhtmlcomment = await marked.parse(
            doc.data().comment.replace(/\\n/g, "\n")
          );
          setEventData({
            id: doc.id,
            title: doc.data().title,
            comment: markedhtmlcomment,
            eki: doc.data().eki,
            ekiID: doc.data().ekiID,
            eventDate: doc.data().eventDate.toDate(),
            eventDateString: eventdatestring,
            gatheringPlace: doc.data().gatheringPlace,
          });
        } else {
          setEventDateString("イベントは中止になりました:id01");
          navigate("/Book");
        }

        //イベント参加者一覧取得と、設定
        checkParticipants();

        //掲示板を確認
        const querySnapshot = await getEventBBSs(eventid);
        let lists: any = [];
        let userlists: any = new Set();
        querySnapshot.forEach((doc: any) => {
          // console.log(doc.id);
          lists.push({
            id: doc.data().id,
            content: doc.data().content,
            uid: doc.data().uid,
          });
          userlists.add(doc.data().uid); // UIDをSetに追加
        });
        userlists = Array.from(userlists); // Setを配列に変換

        let userPublicDataMap: any = {};

        // console.log(userlists);

        if (userlists.length > 0) {
          const querySnapshot2 = await getListOfUsersPublic(userlists);
          // querySnapshot2 をマップ形式に変換
          querySnapshot2.forEach((doc: any) => {
            userPublicDataMap[doc.id] = {
              name: doc.data().name,
              imageurl: doc.data().imageurl,
            };
          });

          setUserDatas(Object.values(userPublicDataMap));

          // lists を userPublicDataMap に基づいて展開し、name と imageurl を追加
          let lists3: any = lists.map((listItem: any) => {
            const userPublicData = userPublicDataMap[listItem.uid] || {};
            return {
              ...listItem,
              name: userPublicData.name || "Unknown",
              imageurl: userPublicData.imageurl || "default_image_url",
            };
          });
          setBBS(lists3);
        } else {
          //コメント無し
        }

        //イベントルート確認
        const querySnapshot2 = await getEventRoutes(eventid);
        let lists4: any = [];

        querySnapshot2.forEach((doc: any) => {
          // console.log(doc.id);
          lists4.push({
            id: doc.id,
            commandId: doc.data().id,
            num: doc.data().num,
            text: doc.data().text,
          });
        });
        setEventRoute(lists4);
      } else {
        alert("中止になりました:id02");

        // eventidのイベントが中止になっているとき
        // userの予約しているイベント一覧から削除できるようにする

        const auth = getAuth(otherApp);
        const user = auth.currentUser;
        if (user) {
          updateUserPrivateBook(user.uid, eventid, { isDelete: true });
        }

        navigate("/Book");
      }
    };
    f();
  }, [changeCount]);

  const PostBBS = async () => {
    if (content) {
      const auth = getAuth(otherApp);
      const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
        if (user) {
          await addEventBBS(eventid, { content: content, uid: user.uid });
          incrementChangeCount();
          setContent("");
          // ここで一度だけ処理を行い、リスナーを解除
          unsubscribe();
        } else {
          console.log("ログインしてください");
          unsubscribe();
        }
      });
    } else {
      console.log("何も記入されていません");
    }
  };

  // イベント参加者の取得
  const checkParticipants = async () => {
    // console.log("move");
    const querySnapshot3 = await getEventParticipants(eventid);
    let lists3: any = [];
    querySnapshot3.forEach((doc: any) => {
      lists3.push({
        uid: doc.data().uid,
        name: doc.data().name,
      });
    });
    // lists3が空かどうかを判定
    if (lists3.length > 0) {
      setEventParticipantsList(lists3);

      if (datas.userInfo.isLogin) {
        const isUserInList = lists3.some(
          (participant: any) => participant.uid === datas.userInfo.userUid
        );
        setIsParticipation(isUserInList);
        setOpendMenu(isUserInList ? "afterBook" : "beforeBook");
      } else {
        setIsParticipation(false);
      }
    } else {
      console.log("参加者リストが空です。");
    }
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  const eventDatas = useMemo(() => {
    return {
      get opendMenu() {
        return opendMenu;
      },
      setOpendMenu,
      get PostBBS() {
        return PostBBS;
      },
      get BBS() {
        return BBS;
      },
      get eventData() {
        return eventData;
      },
      get eventParticipantsList() {
        return eventParticipantsList;
      },
      get content() {
        return content;
      },
      setContent,
      get cancelUser() {
        return cancelUser;
      },
      get bookUser() {
        return bookUser;
      },
      get isParticipation() {
        return isParticipation;
      },
      get EventRoute() {
        return EventRoute;
      },
      setEventRoute,
    };
  }, [
    opendMenu,
    BBS,
    eventData,
    eventParticipantsList,
    content,
    isParticipation,
    EventRoute,
  ]);

  return (
    <>
      <Navbar datas={datas} />

      <div
        style={
          datas.styletext.windowWidth > 1200
            ? styles1.container
            : styles2.container
        }
      >
        <EventView datas={datas} eventDatas={eventDatas} />
      </div>

      <Footer datas={datas} />
    </>
  );
};

export default Event;
