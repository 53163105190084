import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";

import { Button, TextField } from "@mui/material";

import { updateData } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import DeveloperCommand from "./DeveloperCommand";
import DeveloperGatheringPlace from "./DeveloperGatheringPlace";
import DeveloperEventPic from "./DeveloperEventPic";

type Props = {
  datas: Datas;
};

const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  // height: "100vh", //画面の高さいっぱいに表示する場合
};

const lineStyle = {
  display: "inline-block",
  width: "100%",
};

const DeveloperView: React.FC<Props> = ({ datas }) => {
  const [text1, settext1] = useState<string>();
  const [text2, settext2] = useState("");
  const [eki_id, seteki_id] = useState("");
  const [eki_describe, setEki_describe] = useState("");
  const [isEkiChoiced, setIsEkiChoiced] = useState(false);
  const [changing, setChanging] = useState(true);

  const [opendMenu, setOpendMenu] = useState("none");
  const [changeCount, setChangeCount] = useState(0);

  const navigate = useNavigate();

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  return (
    <div style={centerStyle}>
      <div style={{ textAlign: "center" }}>
        <br />
        <br />

        <h1
          style={{
            fontFamily: "'UnifrakturMaguntia', cursive",
            fontSize: "35px",
            fontWeight: 700,
            color: "#2c3e50",
            textAlign: "center",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          サイト管理者画面
        </h1>
        <br />
        <br />
      </div>
      <div style={lineStyle}>
        {datas.routeList.map((item, index) => (
          <Button
            variant="outlined"
            key={item.id}
            onClick={() => {
              settext1(String(item.num));
              settext2(String(item.text));
              seteki_id(String(item.id));
              setEki_describe(String(item.describe));
              setChanging(true);
              setIsEkiChoiced(true);

              setOpendMenu("none");
              //データ読み直しの信号を送る
              incrementChangeCount();
            }}
          >
            {item.num}:{item.text}
          </Button>
        ))}
      </div>
      <br /> <br />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅番号"
        value={text1}
        onChange={(e) => settext1(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅名"
        value={text2}
        onChange={(e) => settext2(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅ID"
        value={eki_id}
        onChange={(e) => seteki_id(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={5}
        minRows={2}
        label="describe"
        value={eki_describe}
        onChange={(e) => {
          setEki_describe(e.target.value);
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          setChanging(true);
          await updateData(eki_id, {
            num: Number(text1),
            text: String(text2),
            describe: String(eki_describe),
          });
          setTimeout(async () => {
            setChanging(false);
          }, 1000); // 1秒の遅延
        }}
        style={{ fontSize: 20 }}
        disabled={!isEkiChoiced}
      >
        駅説明の更新
      </Button>
      <br />
      <br />
      山手線　{text2}駅の特徴について
      <br />
      他の山手線の駅と比較して
      <br />
      50文字以内で教えてください。
      <br />
      <br />
      その特徴とした理由も教えてください
      <br />
      <br />
      <br />
      {isEkiChoiced ? (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              setOpendMenu("EventPic");
            }}
            style={{ fontSize: 20 }}
          >
            イベント画像設定
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              setOpendMenu("Command");
            }}
            style={{ fontSize: 20 }}
          >
            コマンド設定
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              setOpendMenu("GatheringPlace");
            }}
            style={{ fontSize: 20 }}
          >
            集合場所設定
          </Button>

          {opendMenu === "none" ? (
            <></>
          ) : opendMenu === "Command" ? (
            <DeveloperCommand
              datas={datas}
              text1={text1}
              text2={text2}
              eki_id={eki_id}
              changing={changing}
              setChanging={setChanging}
              changeCount={changeCount}
            />
          ) : opendMenu === "GatheringPlace" ? (
            <DeveloperGatheringPlace
              datas={datas}
              text1={text1}
              text2={text2}
              eki_id={eki_id}
              changing={changing}
              setChanging={setChanging}
              changeCount={changeCount}
            />
          ) : opendMenu === "EventPic" ? (
            <DeveloperEventPic
              datas={datas}
              text1={text1}
              text2={text2}
              eki_id={eki_id}
              changing={changing}
              setChanging={setChanging}
              changeCount={changeCount}
            />
          ) : (
            <div>ここは表示されないはず</div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DeveloperView;
